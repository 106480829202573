import { ACCESS_TOKEN_KEY } from 'helper/init.constants'
import React, { Suspense, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes/route.constant'

const AuthLayout = () => {
  const navigate = useNavigate()
  const isLogged = localStorage.getItem(ACCESS_TOKEN_KEY)

  useEffect(() => {
    if (isLogged) return navigate(ROUTE_PATH.HOME)
  }, [])

  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  )
}

export default AuthLayout
