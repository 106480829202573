/* eslint-disable no-useless-escape */
import dayjs from 'dayjs';
import { ACCESS_TOKEN_KEY } from './init.constants';

export const getAuthLocalStorage = () => {
  const authData = localStorage.getItem(ACCESS_TOKEN_KEY);
  return authData && JSON.parse(authData);
};

export const formatMoney = (money) =>
  money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getAccessToken = () => {
  const data = getAuthLocalStorage();
  return data?.accessToken || '';
};

export const getRefreshToken = () => {
  const data = getAuthLocalStorage();
  return data?.refreshToken || '';
};

export const isValidPassword = () => {
  const regex = /^\S{6,}$/;
  return regex;
};

export const isValidEmail = () => {
  // const regex = /^(?![@.])[\w.-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return regex;
};

export const isValidPostalCode = () => {
  const regex = /^(?=.*\d).{6,8}$/;
  return regex;
};

export const isRegexSpecialCharacter = () => {
  const regex = /^[^-.!@#$%^&*()_+=]*$/;
  return regex;
};
export const isRegexSpecialCharacterJP = () => {
  const regex =
    /^(.*[a-zA-Z0-9\u3040-\u30FF\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF\uFF66-\uFF9D].*)+$/;
  return regex;
};
export const isPositiveInteger = () => {
  const regex = /^[1-9]\d*$/;
  return regex;
};

export const isValidPhoneNumber = () => {
  const regex = /^(?=.*\d).{10,11}$/;
  return regex;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const customLabelChart = {
  id: 'customLabelChart',
  afterDatasetsDraw(chart) {
    const {
      ctx,
      chartArea: { top },
    } = chart;
    ctx.save();
    ctx.font = '14px Arial';
    ctx.fillText('件', 27, top - 20);
    ctx.restore();
  },
};

export const convertStatus = (status) => {
  switch (status) {
    case 'Active':
      return (
        <div className="rounded bg-[#E6F7FF] py-[6px] text-center text-[#1890FF]">
          アクティブ
        </div>
      );

    default:
      return (
        <div className="rounded bg-[#FFF7E6] py-[6px] text-center text-[#FA8C16]">
          非アクティブ
        </div>
      );
  }
};

export const convertTagStatus = (status) => {
  switch (status) {
    case 'WAITING_FOR_PAYMENT':
      return (
        <div className="rounded bg-[#EBFFEE] py-[6px] text-center text-[#34C759]">
          支払い待ち
        </div>
      );
    case 'WAITING_FOR_REGISTRATION':
      return (
        <div className="rounded bg-[#FFF7E6] py-[6px] text-center text-[#FA8C16]">
          キャンセル待ち
        </div>
      );
    case 'REGISTERED':
      return (
        <div className="rounded bg-[#E6F7FF] py-[6px] text-center text-[#1890FF]">
          登録済み
        </div>
      );

    default:
      return (
        <div className="rounded bg-[#F9DEDC] py-[6px] text-center text-[#FF3B30]">
          キャンセル
        </div>
      );
  }
};

export const customizeRequiredMark = (label, { required }) => (
  <div className="flex gap-1">
    {label}
    {required && <span style={{ color: 'red' }}>*</span>}
  </div>
);

export const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().endOf('day');
};

export const disabledDateOfBirth = (current) => {
  return current && current >= dayjs().startOf('day');
};
