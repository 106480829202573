/* eslint-disable no-unused-vars */
import { Menu } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTE_PATH } from 'routes/route.constant';
import { SidebarWrapper } from './styled';

function Sidebar() {
  const { pathname } = useLocation();
  const [keySelected, setKeySelected] = useState('');
  const getItem = (label, key, icon, children, type) => ({
    key,
    icon,
    children,
    label,
    type,
  });

  const items = [
    getItem(
      <Link to="#" className="inactive-link">
        アカウント管理
      </Link>,
      'menu1',
      undefined,
      [
        getItem(
          <Link to={ROUTE_PATH.MEMBER_MANAGEMENT}>会員管理</Link>,
          ROUTE_PATH.MEMBER_MANAGEMENT
        ),
        getItem(
          <Link to={ROUTE_PATH.ADMIN_REGISTER}>管理者登録</Link>,
          ROUTE_PATH.ADMIN_REGISTER
        ),
      ]
    ),
    getItem(
      <Link to={ROUTE_PATH.NEWS_MANAGEMENT}>NEWS管理</Link>,
      ROUTE_PATH.NEWS_MANAGEMENT
    ),

    getItem(
      <Link to="#" className="inactive-link">
        商品登録
      </Link>,
      'menu2',
      undefined,
      [
        getItem(
          <Link to={ROUTE_PATH.COURSE_MANAGEMENT}>講座マスタ登録</Link>,
          ROUTE_PATH.COURSE_MANAGEMENT
        ),
        getItem(<Link to={'#'}>セミナーマスタ登</Link>, ' '),
      ]
    ),
    getItem(
      <Link to={ROUTE_PATH.COURSE_SCHEDULE}>開催登録</Link>,
      ROUTE_PATH.COURSE_SCHEDULE
    ),
  ];

  useEffect(() => {
    setKeySelected(pathname);
  }, [pathname]);

  return (
    <SidebarWrapper>
      <div style={{ padding: '32px 70px 15px 65px' }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/icon.png`}
          width="115px"
          alt=""
        />
      </div>
      <Menu
        mode="inline"
        selectedKeys={[keySelected]}
        defaultOpenKeys={['menu1']}
        items={items}
        onClick={(e) => {
          setKeySelected(e.key);
        }}
      />
    </SidebarWrapper>
  );
}

export default Sidebar;
