export const ACCESS_TOKEN_KEY = 'accessToken';
export const ADMIN_INFO = 'Admin_Info';
export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
export const FORMAT_TIME_DEFAULT = 'HH:mm';
export const FORMAT_DATE_DEFAULT = 'YYYY/MM/DD';
export const FORMAT_DATE_SUBMIT = 'YYYY-MM-DD';

export const MEMBERSHIP_TYPE = [
  { value: 'Master_Trainer', label: 'マスタートレーナー' },
  {
    value: 'General_Instructor',
    label: '総合インストラクター',
  },
  { value: 'Instructor', label: 'インストラクター' },
];

export const STATUS = [
  { value: 'Active', label: 'アクティブ' },
  { value: 'Inactive', label: '非アクティブ' },
];

export const CLASSIFICATION_TYPE = [
  {
    value: 'NEW',
    label: '新規受講',
  },
  {
    value: 'RETAKE',
    label: '再受講',
  },
];

export const PAYMENT_STATUS = [
  {
    value: 'WAITING_FOR_PAYMENT',
    label: '支払い待ち',
  },
  {
    value: 'WAITING_FOR_REGISTRATION',
    label: 'キャンセル待ち',
  },
  {
    value: 'REGISTERED',
    label: '登録済み',
  },
  {
    value: 'ADMIN_CANCELED',
    label: 'キャンセル',
  },
];
