import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import Button from '../../components/Button';
import AuthServices from '../../services/AuthServices';

const ConfirmEmail = ({ onBack, isEmail }) => {
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [countdown, setCountdown] = useState(60);
  const [intervalId, setIntervalId] = useState(null);
  const handleSubmit = async () => {
    try {
      const bodyData = {
        email: isEmail,
      };
      setLoading(true);
      const res = await AuthServices.confirmEmail(bodyData);

      if (res.statusCode === 200 || res.statusCode === 201) {
        toast.success('メールアドレスが再送信されました。');
        setDisableSubmit(true);
        startCountdown();
      }
    } finally {
      setLoading(false);
    }
  };

  const hideEmail = (email) => {
    const parts = email.split('@');
    const username = parts[0];
    const domain = parts[1];

    const hiddenUsername = `${username.substring(0, 2)}****${username.slice(
      -1
    )}`;

    const hiddenDomain = `${domain.charAt(0)}*****${domain.slice(-1)}`;
    return `${hiddenUsername}@${hiddenDomain}`;
  };
  const hiddenEmail = hideEmail(isEmail);

  const startCountdown = () => {
    const id = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(id);
          setDisableSubmit(false);
          return 60;
        }
        return prevCountdown - 1;
      });
    }, 1000);
    setIntervalId(id);
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  return (
    <>
      <div>
        <div className="text-welcome">メールアドレスをご確認ください。</div>
        <div>
          <div>
            パスワードリセットの案内を <span>{hiddenEmail}</span>{' '}
            のメールアドレス宛にお送りしました。
          </div>
          <div className="mb-[10px] mt-[40px] 2xl:mb-[30px] 2xl:mt-[60px]">
            パスワードリセットの案内メールが届かない場合は、迷惑メールフォルダまたはプロモーションフォルダーをご確認ください
          </div>
        </div>
      </div>
      <div>
        <Button
          loading={loading}
          className="btn-login my-6"
          type="primary"
          onClick={handleSubmit}
          disabled={disableSubmit}
        >
          メールを再送信する
          {disableSubmit && <div>({countdown})</div>}
        </Button>
        <Button className="btn-back" onClick={onBack}>
          ログイン画面に戻る
        </Button>
      </div>
    </>
  );
};

export default ConfirmEmail;
