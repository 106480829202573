import AppLayout from 'layouts/AppLayout';
import AuthLayout from 'layouts/AuthLayout';
import MainLayout from 'layouts/MainLayout';
import Authentication from 'pages/Authentication';
import PageNotFound from 'pages/PageNotFound';
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { ROUTE_PATH } from './route.constant';

const ChangePassword = React.lazy(
  () => import('../pages/Authentication/ChangePassword')
);
const MemberManagement = React.lazy(() => import('pages/MemberManagement'));
const AdminRegister = React.lazy(() => import('pages/AdminRegister'));
const NewsManagement = React.lazy(() => import('pages/NewsManagement'));
const CourseManagement = React.lazy(() => import('pages/CourseManagement'));
const CourseSchedule = React.lazy(() => import('pages/CourseSchedule'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <PageNotFound />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: ROUTE_PATH.LOGIN,
            element: <Authentication />,
          },

          {
            path: ROUTE_PATH.RESET_PASSWORD,
            element: <ChangePassword />,
          },
        ],
      },
      {
        element: <MainLayout />,
        children: [
          {
            path: ROUTE_PATH.MEMBER_MANAGEMENT,
            element: <MemberManagement />,
          },
          {
            path: ROUTE_PATH.ADMIN_REGISTER,
            element: <AdminRegister />,
          },
          {
            path: ROUTE_PATH.NEWS_MANAGEMENT,
            element: <NewsManagement />,
          },
          {
            path: ROUTE_PATH.COURSE_MANAGEMENT,
            element: <CourseManagement />,
          },
          {
            path: ROUTE_PATH.COURSE_SCHEDULE,
            element: <CourseSchedule />,
          },
        ],
      },
    ],
  },
]);
