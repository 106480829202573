import styled from 'styled-components';

export const AuthenticationWrapper = styled.div`
  min-height: 100vh;
  width: 554px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: 'Inter', sans-serif;

  .btn-back {
    width: 100%;

    font-size: 16px;
    font-weight: 600;
  }

  .text-welcome {
    color: #040308;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-size: 24px;
  }

  @media (min-width: 1536px) {
    .text-welcome {
      font-size: 32px;
      padding: 15px;
    }
  }

  .btn-login {
    width: 100%;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .btn-sign-up,
  .btn-forgot {
    color: #00805a;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .btn-forgot {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
  }
`;

export const ResetPasswordSuccessWrapper = styled.div`
  width: 754px;
  // gap: 100px;
  font-family: 'Inter', sans-serif;
  margin: 20% auto;

  .btn-login {
    width: 100%;
    border-radius: 5px;
    background: #48d3d3;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px;
    height: fit-content;
  }
  .message {
    color: #040308;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-top: 20px;

    line-height: normal;
  }
  .iconSuccess {
    display: flex;
    justify-content: center;
  }
  .iconSuccess > div {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: rgba(52, 168, 83, 0.05);
    border: none;
  }
`;
