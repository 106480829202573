export const ROUTE_PATH = {
  HOME: '/',
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  MEMBER_MANAGEMENT: '/member-management',
  ADMIN_REGISTER: '/admin-register',
  NEWS_MANAGEMENT: '/news-management',
  COURSE_MANAGEMENT: '/course-master-registration',
  COURSE_SCHEDULE: '/course-schedule'
}
