import http from '.'
import { apiForgotPass, apiLogin, apiLogout } from './apiRouter'

const logIn = (body) => http.post(apiLogin, body)

const logOut = (body) => http.post(apiLogout, body)
const confirmEmail = (body) => http.post(apiForgotPass, body)
const updatePass = (body) => http.patch(apiForgotPass, body)

const AuthServices = {
  logIn,
  logOut,
  confirmEmail,
  updatePass,
}

export default AuthServices
