/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavbarWrapper } from './styled';
import { ROUTE_PATH } from 'routes/route.constant';

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const titlePage = () => {
    switch (location.pathname) {
      case ROUTE_PATH.MEMBER_MANAGEMENT:
        return <div className="text-header">会員管理</div>;
      case ROUTE_PATH.ADMIN_REGISTER:
        return <div className="text-header">管理者登録</div>;

      case ROUTE_PATH.GROUP_REGISTRATION:
        return (
          <div className="text-header">
            <div>問い合わせ</div>
            <div className="sub-title">団体登録申請</div>
          </div>
        );
      case ROUTE_PATH.COURSE_SCHEDULE:
        return <div className="text-header">開催登録</div>;

      default:
        break;
    }
  };

  const handleChangPass = () => {};

  return (
    <NavbarWrapper>
      <div className="admin">
        <div style={{ cursor: 'pointer' }}>
          <img
            onClick={handleChangPass}
            src={`${process.env.PUBLIC_URL}/images/admin.png`}
            width="40px"
            alt=""
          />
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            localStorage.clear();
            navigate(ROUTE_PATH.LOGIN);
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/logout.png`}
            width="24px"
            alt=""
          />
        </div>
      </div>
      {titlePage()}
    </NavbarWrapper>
  );
}

export default Navbar;
