import styled from 'styled-components'

export const SidebarWrapper = styled.div`
  height: 100vh;

  .ant-menu-item.ant-menu-item-selected {
    border-right: 2px solid rgba(237, 120, 54, 1);
    background: rgba(237, 120, 54, 0.1);
  }

  .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  }

  .ant-menu-item {
    border-radius: 0;
    margin: 0;
    width: 100%;
  }

  .inactive-link {
    color: inherit !important;
  }
`

export const NavbarWrapper = styled.div`
  background-color: #fff;

  .admin {
    height: 70px;
    display: flex;
    padding: 0 24px;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  .text-header {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #ed7836;
    height: 54px;
    padding: 10px 24px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    border-top: 1px solid #e6ebf1;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  }

  .sub-title {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #5bd4c1;
  }

  .sub-title::before {
    content: '';
    display: inline-block;
    height: 4px;
    width: 4px;
    border-radius: 4px;
    background-color: #5bd4c1;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 10px;
  }
`
