import { StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      hashed: false,
      token: {
        // Seed Token
        controlHeight: 40,
        borderRadius: 10,
        colorPrimary: '#ED7836',
        marginLG: 20,
      },

      components: {
        Button: {
          algorithm: true,
          autoInsertSpace: false,
        },
        Checkbox: {
          algorithm: true,
          controlHeight: 32,
        },
      },
    }}
  >
    <StyleProvider layer>
      <App />
    </StyleProvider>
  </ConfigProvider>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
