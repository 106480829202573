// Auth
export const apiLogin = '/auth/sign-in';
export const apiLogout = '/auth/log-out';
export const apiRefreshToken = '/auth/refresh';
export const apiForgotPass = '/auth/forgot-password';

// Users
export const apiGetMe = '/users/me';
export const apiUsers = '/users';
export const apiImportUser = '/users/import';
export const apiChangePass = '/users/change-password';
export const apiUserCourses = '/users/courses';

// Files
export const apiFile = '/files';

// Admins
export const apiAdmins = '/admins';

// Courses
export const apiCourses = '/courses';

// Course Schedules
export const apiCourseSchedule = '/course-schedules';
export const apiRegions = '/course-schedules/regions';
export const apiQrCode = '/course-schedules/qrcode';

// Settings
export const apiSetting = '/settings';

// News
export const apiNews = '/news';
