/* eslint-disable no-unused-vars */
import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import Button from 'components/Button';
import AuthServices from 'services/AuthServices';
import { ACCESS_TOKEN_KEY } from 'helper/init.constants';
import { ROUTE_PATH } from 'routes/route.constant';
import { isValidEmail, isValidPassword } from 'helper/utils';

const LoginForm = ({ onToggle }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // call api login
  const handleSubmit = () => {
    form
      .validateFields()
      .then((val) => {
        setLoading(true);
        const body = {
          email: val.email,
          password: val.password,
          environment: 'WEB_ADMIN',
        };
        AuthServices.logIn(body)
          .then((res) => {
            if (res.isOk) {
              toast.success('ログインに成功しました');
              localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(res.data));
              navigate(ROUTE_PATH.HOME);
            }
          })
          .catch(() => {})
          .finally(() => setLoading(false));
      })
      .catch(() => {});
  };

  return (
    <>
      <div className="text-welcome" style={{ paddingBottom: '60px' }}>
        おかえりなさい
      </div>
      <div>
        <Form form={form}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'メールアドレスを入力してください',
              },
              {
                pattern: isValidEmail(),
                message: '有効なメールアドレスを入力してください',
              },
            ]}
            name={'email'}
          >
            <Input
              placeholder="メールアドレス"
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleSubmit();
              }}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'パスワードを入力してください',
              },
              {
                pattern: isValidPassword(),
                message:
                  'パスワードは少なくとも 6 文字以上にする必要があります。',
              },
            ]}
            name={'password'}
          >
            <Input.Password
              placeholder="パスワード "
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleSubmit();
              }}
            />
          </Form.Item>
        </Form>
        <div className="btn-forgot" onClick={onToggle}>
          パスワードを忘れた場合
        </div>
        <Button
          type="primary"
          loading={loading}
          className="btn-login"
          onClick={handleSubmit}
        >
          ログイン
        </Button>
      </div>
    </>
  );
};

export default LoginForm;
