import { Form, Input } from 'antd';
import Button from '../../components/Button';
import { isValidEmail } from '../../helper/utils';

const ForgotPass = ({ onToggle, onBack, onChangeEmail }) => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        onToggle();
      })
      .catch(() => {});
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    onChangeEmail(value);
  };
  return (
    <>
      <div className="mb-10 2xl:mb-[60px]">
        <div className="text-welcome">パスワード再設定</div>
        <div>
          <div>登録しているメールアドレスをご入力ください。</div>
          <div>新しいパスワードを設定するための案内をお送りします。</div>
        </div>
      </div>
      <div>
        <Form form={form}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'メールアドレスを入力してください',
              },
              {
                pattern: isValidEmail(),
                message: '有効なメールアドレスを入力してください',
              },
            ]}
            name={'email'}
          >
            <Input placeholder="メールアドレス" onChange={handleEmailChange} />
          </Form.Item>
        </Form>
        <Button
          className="btn-login"
          style={{ margin: '24px 0' }}
          onClick={handleSubmit}
          type="primary"
        >
          送信
        </Button>
        <Button className="btn-back" onClick={onBack}>
          ログイン画面に戻る
        </Button>
      </div>
    </>
  );
};

export default ForgotPass;
