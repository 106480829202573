import { Layout, theme } from 'antd'
import { Suspense, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { ACCESS_TOKEN_KEY } from 'helper/init.constants'
import { ROUTE_PATH } from 'routes/route.constant'

const { Content, Sider } = Layout

function MainLayout() {
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const navigate = useNavigate()
  const isLogin = localStorage.getItem(ACCESS_TOKEN_KEY)

  useEffect(() => {
    if (!isLogin) return navigate(ROUTE_PATH.LOGIN)
  })

  return (
    <Layout>
      <Sider
        width={250}
        style={{
          background: colorBgContainer,
          borderRight: '1px solid rgba(0, 0, 0, 0.10)',
        }}
      >
        <Sidebar />
      </Sider>
      <Layout>
        <Suspense fallback={null}>
          <Navbar />

          <Content style={{ padding: '20px' }}>
            <div className="rounded-xl bg-white p-5">
              <Outlet />
            </div>
          </Content>
        </Suspense>
      </Layout>
    </Layout>
  )
}

export default MainLayout
