import React, { useState } from 'react'
import { AuthenticationWrapper } from './styled'
import LoginForm from './LoginForm'
import ForgotPass from './ForgotPass'
import ConfirmEmail from './ConfirmEmail'
const Authentication = () => {
  const [isLogin, setIsLogin] = useState(0)
  const [isEmail, setIsEmail] = useState()

  const onToggle = () => {
    setIsLogin(isLogin + 1)
  }

  const onToggle1 = () => {
    setIsLogin(isLogin - 1)
  }

  const onToggle2 = () => {
    setIsLogin(0)
  }

  const onChangeEmail = (value) => {
    setIsEmail(value)
  }
  return (
    <AuthenticationWrapper>
      <div className="w-[484px] 2xl:w-[554px]">
        <img
          src={process.env.PUBLIC_URL + '/images/icon.png'}
          className="mx-auto mb-[40px] w-[200px] 2xl:mb-[100px] 2xl:w-[260px]"
          alt=""
        />

        {isLogin === 0 && <LoginForm onToggle={onToggle} />}
        {isLogin === 1 && (
          <ForgotPass
            onToggle={onToggle}
            onBack={onToggle1}
            onChangeEmail={onChangeEmail}
          />
        )}
        {isLogin === 2 && (
          <ConfirmEmail
            onToggle={onToggle}
            onBack={onToggle2}
            isEmail={isEmail}
          />
        )}
      </div>
    </AuthenticationWrapper>
  )
}

export default Authentication
